<template>
  <div>
    <div v-if="!is_loading_data">
      <div class="responsive-header mb-4">
        <div class="d-flex responsive-header--left" style="gap: 12px">
          <b-button variant="primary" style="border-radius: 8px !important;" @click="toAllInvoice">
            <i class="fas fa-arrow-left"></i>
            {{ $t('invoice.topic') }}
          </b-button>
          <div class="invoice-status">
            {{ $t('invoice.list.status') }} :
            <b-badge pill class="px-2 text-white badge"
                     :class="colorButton(invoice_status)">
              {{ filterValue(optionStatus, invoice_status) }}
            </b-badge>
          </div>
          <div class="invoice-status">
            {{
              invoice_status === 'paid' || invoice_status === 'pre-credit-paid' ? $t('invoice.type_receipt') :
                  $t('invoice.type_invoice')
            }} :
            <b-badge pill class="px-2 text-white badge ml-2">
              {{ is_juristic ? $t('subscription.checkout.juristic') : $t('subscription.checkout.personal') }}
            </b-badge>
          </div>
        </div>
        <div>
          <b-button variant="danger" style="border-radius: 8px !important;" v-if="invoice_status === 'unpaid'"
                    @click="showCaseCancelInvoice()">
            {{ $t('subscription.checkout.cancel_subscription') }}
            <b-spinner small v-if="is_cancel_loading" class="mt-1"/>
          </b-button>
        </div>
      </div>
      <div class="responsive-invoice-detail"
           :class="{'responsive-invoice-detail-custom': invoice_status === 'cancelled'}">
        <div class="grid-item">
          <div class="reason-box" v-if="invoice_status === 'cancelled'">
            <div class="text-bold">
              <div>{{ $t('subscription.checkout.reason_for_cancelled') }}</div>
            </div>
            <div class="ml-3" v-if="form.cancel_by === 'customer'">
              {{ changeMapReasonCancel(form.cancel_reasons) || '-' }}
            </div>
            <div class="ml-3" v-else-if="form.cancel_by === 'admin'">
              {{ $t('subscription.checkout.cancel_by_admin') }}
            </div>
          </div>
          <div class="text-center" v-if="form.pdf_url">
            <iframe :src="form.pdf_url" style="width:100%;height:80vh;border:2px solid #FFFFFF;border-radius: 14px;"
                    scrolling="auto"/>
          </div>
        </div>
        <b-card class="grid-item"
                v-if="checkPermission('upload-slip', 'action', 'all-invoices') && invoice_status !== 'cancelled'">
          <b-row>
            <b-col lg="12" md="12" sm="12" class="mb-3">
              <div class="mb-3">
                <h4>
                  {{
                    $t('subscription.checkout.upload_transfer_slip')
                  }}</h4>
                <div class="remark-upload-slip">
                  {{ $t('subscription.checkout.remark_upload_slip') }}
                </div>
                <div class="d-flex">
                  <div class="text-info font-12 mr-2">{{ $t('subscription.checkout.only_support_img_file') }}</div>
                  <div class="text-danger font-12"><sup>*</sup>{{ $t('subscription.checkout.max_file_10_file') }}
                  </div>
                </div>
                <upload-image-multiple-component v-model="form.slips" @input="setImage"
                                                 :sub-label="$t('common.uploadImg')"
                                                 :max-file="form.slips.length >= 10"
                                                 accept="image/png, image/jpeg, .pdf"
                                                 custom_class="p-0"
                                                 :close-icon="invoice_status === 'pending' || invoice_status === 'unpaid'|| invoice_status === 'pre-credit-approved'"/>
              </div>
              <div class="mb-3">
                <div>
                  <h4>{{ $t('subscription.checkout.upload_tax_file') }}</h4>
                  <div class="d-flex">
                    <div class="text-info font-12 mr-2">{{
                        $t('subscription.checkout.only_support_img_file')
                      }}
                    </div>
                    <div class="text-danger font-12"><sup>*</sup>{{ $t('subscription.checkout.max_file') }}</div>
                  </div>
                </div>
                <upload-file-multiple-component v-model="form.withholding_slips" @input="setImageWithHoldingSlip"
                                                :sub-label="$t('common.uploadTitle')"
                                                accept="image/png, image/jpeg, .pdf"
                                                :max-file="form.withholding_slips.length >= 3"
                                                custom_class="p-0"
                                                :close-icon="invoice_status === 'pending' || invoice_status === 'unpaid'"/>
              </div>
            </b-col>
            <b-col lg="12" md="12" sm="12">
              <div class="bank-box">
                <img v-if="bankDetail?.logo" :src="bankDetail?.logo" width="80" height="80" class="mr-3" alt="logo">
                <div>
                  <p class="m-0 d-flex flex-column" style="gap: 4px">
                    <span>{{ bankDetail?.bank_name }}  </span>
                    <span class="custom-bank-number">{{ bankDetail?.bank_account_number }}</span>
                    <span>{{ bankDetail?.account_name }}</span>
                    <div class="text-danger">{{ $t('invoice.pdf.paymentChannel') }}</div>
                  </p>
                </div>
              </div>
              <div>
                <b-button variant="primary" block class="mt-4" @click="uploadSlip"
                          style="border-radius: 8px !important;"
                          v-if="invoice_status !== 'paid' && invoice_status !== 'cancelled' && invoice_status !== 'pre-credit-paid'"
                          :disabled="form.slips.length <= 0 || is_loading"><i class="fas fa-save"></i>
                  {{ $t('button.uploadSlip') }}
                  <b-spinner small label="Small Spinner" v-if="is_loading"></b-spinner>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    <div v-else class="text-primary flex-middle load-content-card">
      <b-spinner style="width: 3rem; height: 3rem" class="align-middle mr-2"></b-spinner>
      <strong> {{ $t('loading') }}</strong>
    </div>
    <!--  cancel subscription  -->
    <div>
      <b-modal id="cancel-invoice" size="md" hide-footer no-close-on-backdrop no-close-on-esc centered>
        <template #modal-header="{ close }">
          <div></div>
          <i class="fas fa-times hover-icon action-button" @click="closePopUp()"/>
        </template>
        <div class="mt-0 mb-4 ml-4 mr-4">
          <div class="text-center">
            <h4 class="text-danger">{{ $t('subscription.checkout.reason_for_cancelled') }}</h4>
            <div>
              {{ $t('subscription.checkout.more_then_selected') }}
            </div>
          </div>
          <div class="p-4">
            <b-form-group
                class="ml-5"
                v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox
                  v-for="option in cancel_reasons_list"
                  v-model="cancel.cancel_reasons"
                  :key="option.value"
                  :value="option.value"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-3a"
              >
                {{ $t(option.text) }}
              </b-form-checkbox>
            </b-form-group>
          </div>
          <div>
            <b-button class="w-100 " variant="primary"
                      style="border-radius: 8px !important;"
                      :disabled="cancel.cancel_reasons.length <= 0 || is_cancel_subscription"
                      @click="createCancelSubscription">
              {{ $t('common.confirm') }}
              <b-spinner small v-if="is_cancel_subscription" class="mt-1"/>
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import subscriptionApi from "@/repository/subscriptionApi";
import UploadFileComponent from "@/components/common/uploadFileComponent";
import UploadImageComponent from "@/components/common/uploadImg";
import UploadImageMultipleComponent from "@/components/common/uploadImgMultiple";
import alert from "@/common/alert";
import functions from "@/common/functions";
import functionsCommon from "@/common/functions";
import masterData from "@/common/masterData.json";
import UploadFileMultipleComponent from "@/components/common/uploadFileMultiple";
import OverlayPage from "@/components/common/overlayPage.vue";

export default {
  name: "view-invoice",
  components: {
    OverlayPage,
    UploadFileMultipleComponent, UploadImageMultipleComponent, UploadImageComponent, UploadFileComponent
  },
  data: () => ({
    form: {
      pdf_url: '',
      slips: [],
      bank_account_id: '',
      withholding_slips: [],
      cancel_reasons: '',
      cancel_by: ''
    },
    optionStatus: masterData?.invoiceStatus,
    invoice_status: '',
    bankDetail: {},
    is_loading: false,
    is_show_btn: false,
    is_juristic: false,
    is_cancel_loading: false,
    cancel_reasons_list: [
      {text: 'subscription.checkout.need_to_modify_order', value: 0},
      {text: 'subscription.checkout.need_to_changed_package', value: 1},
      {text: 'subscription.checkout.other_or_change_your_mind', value: 2},
    ],
    cancel: {
      cancel_reasons: []
    },
    is_cancel_subscription: false,
    is_loading_data: false
  }),
  created() {
    this.subscription_id = this.$route.query.subscription
    if (this.subscription_id) {
      this.getSubscriptionByID()
    }
  },
  methods: {
    colorButton(data) {
      return functions.colorButtonStatusByText(data)
    },
    getSubscriptionByID() {
      this.is_loading_data = true
      subscriptionApi.getSubscriptionById(this.subscription_id).then(resp => {
        this.is_show_btn = resp?.slips?.length > 0
        this.form = {
          ...this.form,
          slips: resp?.slips,
          pdf_url: resp?.pdf_url && !_.isObject(resp.pdf_url) ? resp?.pdf_url + '#zoom=44' : resp?.invoice_url + '#zoom=44',
          bank_account_id: resp?.bank_account_id,
          withholding_slips: resp?.withholding_slips,
          cancel_reasons: resp?.cancel_reasons,
          cancel_by: resp?.cancel_by
        }
        this.is_juristic = resp?.customer?.is_juristic
        this.invoice_status = resp?.invoice_status
        this.getBankAccountList()
      }).finally(() => {
        this.is_loading_data = false
      })
    },
    getBankAccountList() {
      subscriptionApi.getBankAccountList().then(resp => {
        const rawData = resp?.data.filter(item => {
          return item._id === this.form.bank_account_id
        })
        this.bankDetail = rawData[0]
      }).catch(err => {
        throw err
      })
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    uploadSlip() {
      this.is_loading = true
      const data = {
        slips: this.form.slips,
        withholding_slips: this.form.withholding_slips
      }
      subscriptionApi.uploadSlipInvoice(data, this.subscription_id).then(resp => {
        if (resp) {
          this.form.slips = resp?.slips
          alert.updateSuccess(this)
          this.getSubscriptionByID()
        } else {
          alert.updateFailed(this, resp?.message)
        }
      }).catch(err => {
        alert.updateFailed(this, err.response?.data?.message)
      }).finally(() => {
        this.is_loading = false
      })
    },
    setImage(value) {
      this.form.slips = value
    },
    setImageWithHoldingSlip(value) {
      this.form.withholding_slip = value
    },
    toAllInvoice() {
      this.$router.push('/Invoice').catch(() => {
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    changeMapReasonCancel(data) {
      let map_data = data?.split(',').map((item) => {
        let msg = ''
        if (item === '0') {
          msg = this.$t('subscription.checkout.need_to_modify_order')
        } else if (item === '1') {
          msg = this.$t('subscription.checkout.need_to_changed_package')
        } else if (item === '2') {
          msg = this.$t('subscription.checkout.other_or_change_your_mind')
        } else {
          msg = null
        }
        return msg
      })
      return !_.isNil(map_data) ? map_data?.join(' , ') : null
    },
    showCaseCancelInvoice() {
      this.$bvModal.show('cancel-invoice')
    },
    createCancelSubscription() {
      const map_data = {
        id: this.subscription_id,
        cancel_reasons: this.cancel.cancel_reasons
      }
      this.is_cancel_subscription = true
      subscriptionApi.cancelSubscriptionById(map_data).then(resp => {
        if (!!resp) {
          this.closePopUp()
          this.getSubscriptionByID()
        }
      }).catch(err => {
        if (err?.response?.status !== 401) {
          alert.cancelFailed(this, err?.response?.data?.message)
        }
      }).finally(() => {
        this.is_cancel_subscription = false
      })
    },
    closePopUp() {
      this.$bvModal.hide('cancel-invoice')
      this.cancel.cancel_reasons = []
    }
  },
  watch: {
    '$i18n.locale'(val) {
      if (val) {
        this.getSubscriptionByID()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.responsive-invoice-detail-custom {
  grid-template-columns: repeat(1, 1fr) !important;
  width: 100%;

  .grid-item:first-child {
    grid-column: initial !important;
  }
}

.responsive-invoice-detail {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;

  .grid-item:first-child {
    grid-column: span 2;
  }

  .grid-item:last-child {
    grid-column: span 1;
  }

  .custom-bank-number {
    font-size: 24px;
  }

  @media (max-width: 768px) {
    justify-content: initial;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .grid-item:first-child {
      grid-column: initial;
    }

    .grid-item:last-child {
      grid-column: initial;
    }

    .custom-bank-number {
      font-size: 16px;
    }
  }
}

.responsive-header {
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    .responsive-header--left {
      flex-direction: column;
      align-items: flex-start;
      justify-content: initial;
      width: 100%;
    }

    flex-direction: column;
    align-items: flex-start;
    justify-content: initial;
    gap: 12px;
  }
}

.bank-box {
  &.header {
  }

  background: #FFFFFF;
  padding: 24px;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  color: #000000;
  display: flex;
  gap: 12px;
  width: 100%;
  //margin-top: 1rem;

}

.invoice-status {
  background: #e9e9e9;
  color: #000000;
  border-radius: 8px;
  //height: 35px;
  //vertical-align: center;
  padding: 6px;
  //display: block;
}

.invoice-status-test {
  background: #e9e9e9;
  color: #000000;
  border-radius: 8px;
  //height: 35px;
  //vertical-align: center;
  padding: 6px;
  border-color: #e9e9e9;
  //display: block;
}

.reason-box {
  background: #FFE9E9;
  padding: 1rem;
  border-radius: 10px;
  margin: 1rem 0 1rem 0;
  display: flex;
  color: #D30000;
}

.load-content-card {
  min-height: calc(100vh - 200px);
}
</style>