import { render, staticRenderFns } from "./2c2p.vue?vue&type=template&id=b181f69c&scoped=true"
import script from "./2c2p.vue?vue&type=script&lang=js"
export * from "./2c2p.vue?vue&type=script&lang=js"
import style0 from "./2c2p.vue?vue&type=style&index=0&id=b181f69c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b181f69c",
  null
  
)

export default component.exports