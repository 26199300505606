<template>
  <div>
    <div v-if="payment_method === 'offline_payment'">
      <view-invoice/>
    </div>
    <div v-else-if="payment_method === 'qrcode'">
      <view-qrcode/>
    </div>
    <div v-else-if="payment_method === '2c2p'">
      <view2c2p/>
    </div>
  </div>
</template>

<script>

import ViewInvoice from "@/views/invoice/invoice.vue";
import ViewQrcode from "@/views/invoice/qrcode.vue";
import View2c2p from "@/views/invoice/2c2p.vue";

export default {
  name: "invoice",
  components: {View2c2p, ViewQrcode, ViewInvoice},
  data() {
    return {
      payment_method: null
    }
  },
  mounted() {
    this.payment_method = this.$route.query.payment
  }

}
</script>

<style scoped lang="scss">

</style>