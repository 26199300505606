<template>
  <div>
    <div v-if="!loading">
      <div class="responsive-header mb-4">
        <div class="d-flex responsive-header--left" style="gap: 12px">
          <b-button variant="primary" style="border-radius: 8px !important;" @click="toAllInvoice">
            <i class="fas fa-arrow-left"></i>
            {{ $t('invoice.topic') }}
          </b-button>
          <div class="invoice-status">
            {{ $t('invoice.list.status') }} :
            <b-badge pill class="px-2 text-white badge"
                     :class="colorButton(form.invoice_status)">
              {{ filterValue(optionStatus, form.invoice_status) }}
            </b-badge>
          </div>
          <div class="invoice-status">
            {{
              form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-paid' ? $t('invoice.type_receipt') : $t('invoice.type_invoice')
            }} :
            <b-badge pill class="px-2 text-white badge ml-2">
              {{ form.is_juristic ? $t('subscription.checkout.juristic') : $t('subscription.checkout.personal') }}
            </b-badge>
          </div>
        </div>
        <div>
          <b-button variant="danger" @click="cancelSubscription()"
                    v-if="form.invoice_status === 'unpaid'"
                    :disabled="is_cancel_loading"
                    style="border-radius: 8px"
          >
            {{ $t('subscription.checkout.cancel_subscription') }}
          </b-button>
        </div>
      </div>
      <div class="reason-box" v-if="form.invoice_status === 'cancelled'">
        <div class="text-bold">
          <div>{{ $t('subscription.checkout.reason_for_cancelled') }}</div>
        </div>
        <div class="ml-3" v-if="form.cancel_by === 'customer'">
          {{ changeMapReasonCancel(form.cancel_reasons) || '-' }}
        </div>
        <div class="ml-3" v-else-if="form.cancel_by === 'admin'">
          {{ $t('subscription.checkout.cancel_by_admin') }}
        </div>
      </div>
      <!--      large-screen  -->
      <div class="responsive-invoice-detail"
           :class="{'responsive-invoice-detail-custom': form.invoice_status === 'cancelled'}"
           v-if="innerWidth > 1024"
      >
        <div class="text-center grid-item" v-if="form.pdf_url">
          <iframe :src="form.pdf_url"
                  style="width:100%;height:80vh;border:2px solid #FFFFFF;border-radius: 14px;"
                  scrolling="auto"/>
        </div>
        <b-card v-if="form.invoice_status !== 'cancelled'" class="grid-item">
          <div class="d-flex justify-content-start align-items-center" style="gap: 8px">
            <p class="m-0">{{ $t('subscription.checkout.qr_code.payment_method') }} :</p>
            <p class="m-0 font-weight-bold" style="font-size: 16px">{{
                $t('subscription.checkout.credit_card.pay_credit')
              }}</p>
          </div>
          <hr/>
          <div class="mt-3 mb-3 text-center">
            <div v-if="form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-approved'"
                 class="p-3 mt-3">
              <b-card class="qr-code-box">
                <div class="m-5">
                  <div>
                    <img src="@/assets/images/icon/check-mark.svg" alt="check-mark" width="120" height="120">
                  </div>
                  <div class="text-success text-bold mt-3">
                    {{ $t('subscription.checkout.qr_code.payment_success') }}
                  </div>
                  <div class="text-content-black mt-2 mb-5">
                    {{ $t('subscription.checkout.qr_code.check_payment_detail') }}
                  </div>
                </div>
              </b-card>
            </div>
            <div v-else>
              <div v-if="!webPaymentUrl" class="text-left mt-3 text-danger">
                <sup>*</sup>{{ $t('subscription.checkout.credit_card.select_payment_method') }}
              </div>
              <b-row>
                <b-col sm="12" md="5" lg="5" xl="5" class="bank-card m-2"
                       v-for="credit_card in payment_2c2p_list"
                       :key="credit_card._id"
                       :class="{'bank-card-active': account_2c2p_id === credit_card.account_id}"
                       @click="selectBankAccount(credit_card.account_id, '2c2p', credit_card.account_no)">
                  <div style="position: relative;transform: translate(-50%, -50%); left: 50%;top: 50%;">
                    <div v-if="credit_card.account_no === '01'">
                      <h4>
                        Credit / Debit Card
                      </h4>
                      <div>
                        <b-row class="justify-content-center text-center">
                          <b-col cols="auto" v-for="(icon, index) in credit_card.icons" :key="index">
                            <div :class="icon ? 'credit-card-img' : 'bank-img-empty'">
                              <img :src="icon" alt="bank-logo">
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div v-if="credit_card.account_no === '02'">
                      <h4>
                        E-wallet / Digital Payment
                      </h4>
                      <div>
                        <b-row class="justify-content-center text-center">
                          <b-col cols="auto" v-for="(icon, index) in credit_card.icons" :key="index">
                            <div :class="icon ? 'credit-card-img' : 'bank-img-empty'">
                              <img :src="icon" alt="bank-logo">
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="mt-4 mb-4" v-if="webPaymentUrl">
            <iframe :src="webPaymentUrl"
                    style="width:100%;height:60vh;border-radius: 14px;border: 1px solid #f3f3f3;"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    scrolling="auto"/>
          </div>
        </b-card>
      </div>
      <div v-if="innerWidth <= 1024"
           class="responsive-invoice-detail"
           :class="{'responsive-invoice-detail-custom': form.invoice_status === 'cancelled'}">
        <b-card v-if="form.invoice_status !== 'cancelled'" class="grid-item">
          <div class="d-flex justify-content-start align-items-center" style="gap: 8px">
            <p class="m-0">{{ $t('subscription.checkout.qr_code.payment_method') }} :</p>
            <p class="m-0 font-weight-bold" style="font-size: 16px">{{
                $t('subscription.checkout.credit_card.pay_credit')
              }}</p>
          </div>
          <hr/>
          <div class="mt-3 mb-3 text-center">
            <div v-if="form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-approved'"
                 class="p-3 mt-3">
              <b-card class="qr-code-box">
                <div class="m-5">
                  <div>
                    <img src="@/assets/images/icon/check-mark.svg" alt="check-mark" width="120" height="120">
                  </div>
                  <div class="text-success text-bold mt-3">
                    {{ $t('subscription.checkout.qr_code.payment_success') }}
                  </div>
                  <div class="text-content-black mt-2 mb-5">
                    {{ $t('subscription.checkout.qr_code.check_payment_detail') }}
                  </div>
                </div>
              </b-card>
            </div>
            <div v-else>
              <div v-if="!webPaymentUrl" class="text-left mt-3 text-danger">
                <sup>*</sup>{{ $t('subscription.checkout.credit_card.select_payment_method') }}
              </div>
              <b-row>
                <b-col sm="12" md="5" lg="5" xl="5" class="bank-card m-2"
                       v-for="credit_card in payment_2c2p_list"
                       :key="credit_card._id"
                       :class="{'bank-card-active': account_2c2p_id === credit_card.account_id}"
                       @click="selectBankAccount(credit_card.account_id, '2c2p', credit_card.account_no)">
                  <div style="position: relative;transform: translate(-50%, -50%); left: 50%;top: 50%;">
                    <div v-if="credit_card.account_no === '01'">
                      <h4>
                        Credit / Debit Card
                      </h4>
                      <div>
                        <b-row class="justify-content-center text-center">
                          <b-col cols="auto" v-for="(icon, index) in credit_card.icons" :key="index">
                            <div :class="icon ? 'credit-card-img' : 'bank-img-empty'">
                              <img :src="icon" alt="bank-logo">
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div v-if="credit_card.account_no === '02'">
                      <h4>
                        E-wallet / Digital Payment
                      </h4>
                      <div>
                        <b-row class="justify-content-center text-center">
                          <b-col cols="auto" v-for="(icon, index) in credit_card.icons" :key="index">
                            <div :class="icon ? 'credit-card-img' : 'bank-img-empty'">
                              <img :src="icon" alt="bank-logo">
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="mt-4 mb-4" v-if="webPaymentUrl">
            <iframe :src="webPaymentUrl"
                    style="width:100%;height:60vh;border-radius: 14px;border: 1px solid #f3f3f3;"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    scrolling="auto"/>
          </div>
        </b-card>
        <div class="text-center grid-item mt-3" v-if="form.pdf_url">
          <iframe :src="form.pdf_url"
                  style="width:100%;height:80vh;border:2px solid #FFFFFF;border-radius: 14px;"
                  scrolling="auto"/>
        </div>
      </div>
      <div>
        <b-modal id="show-qrcode-cancel" size="md" hide-footer no-close-on-backdrop no-close-on-esc centered>
          <template #modal-header="{ close }">
            <div></div>
            <i class="fas fa-times hover-icon action-button" @click="closePopUp()"/>
          </template>
          <div class="mt-0 mb-4 ml-4 mr-4">
            <div class="text-center">
              <h4 class="text-danger">{{ $t('subscription.checkout.reason_for_cancelled') }}</h4>
              <div>
                {{ $t('subscription.checkout.more_then_selected') }}
              </div>
            </div>
            <div class="p-4">
              <b-form-group
                  class="ml-5"
                  v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox
                    v-for="option in cancel_reasons_list"
                    v-model="cancel.cancel_reasons"
                    :key="option.value"
                    :value="option.value"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-3a"
                >
                  {{ $t(option.text) }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div>
              <b-button class="w-100 " variant="primary"
                        :disabled="cancel.cancel_reasons.length <= 0 || is_cancel_loading"
                        @click="createCancelSubscription">
                {{ $t('common.confirm') }}
                <b-spinner small v-if="is_cancel_loading" class="mt-1"/>
              </b-button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>

import subscriptionApi from "@/repository/subscriptionApi";
import functionsCommon from "@/common/functions";
import masterData from "@/common/masterData.json";
import OverlayPage from "@/components/common/overlayPage.vue";
import alert from "@/common/alert";
import {mapState} from "vuex";
import functions from "@/common/functions";
import router from "@/router/Router";
import authApi from "@/repository/authApi";
import settingApi from "@/repository/settingApi";

export default {
  name: "view-2c2p",
  components: {OverlayPage},
  data() {
    return {
      form: {
        slips: '',
        pdf_url: '',
        bank_account_id: '',
        is_juristic: '',
        invoice_status: '',
        grand_total: 0,
        qr_code_img: '',
        qr_code_exp: '',
        reference1: '',
        reference2: '',
        transaction_date: '',
        cancel_reasons: '',
        cancel_by: ''
      },
      loading: false,
      is_loading_qrcode: false,
      optionStatus: masterData?.invoiceStatus,
      countdown: '00:00',
      timer: 0,
      time: 0,
      is_payment_success: false,
      is_cancel_loading: false,
      cancel_reasons_list: [
        {text: 'subscription.checkout.need_to_modify_order', value: 0},
        {text: 'subscription.checkout.need_to_changed_package', value: 1},
        {text: 'subscription.checkout.other_or_change_your_mind', value: 2},
      ],
      cancel: {
        cancel_reasons: []
      },
      subscription_id: null,
      err_qr_code: false,
      account_2c2p_id: null,
      webPaymentUrl: '',
      payment_2c2p_list: [],
      invoice_id: ''
    }
  },
  computed: {
    ...mapState({
      innerWidth: state => state.theme.width_inner,
      confirm_payment: state => state.subscription.confirm_payment,
      time_income: state => state.subscription.time,
    })
  },
  mounted() {
    this.err_qr_code = false
    this.subscription_id = this.$route.query.subscription
    if (this.subscription_id) {
      this.getSubscriptionByID()
    }
  },
  methods: {
    colorButton(data) {
      return functions.colorButtonStatusByText(data)
    },
    getSubscriptionByID() {
      this.loading = true
      this.is_loading_qrcode = true
      subscriptionApi.getSubscriptionById(this.subscription_id).then(resp => {
        if (!!resp) {
          let plan = _.get(resp, 'plan', {})
          this.form = {
            ...this.form,
            slips: resp?.slips,
            pdf_url: resp?.pdf_url ? resp?.pdf_url + '#zoom=44' : resp?.invoice_url + '#zoom=44',
            is_juristic: resp?.customer?.is_juristic,
            invoice_status: resp?.invoice_status,
            grand_total: this.changeFormatTotal(plan?.grand_total, plan?.currency_format),
            reference1: resp?.ref1,
            reference2: resp?.ref2,
            cancel_reasons: resp?.cancel_reasons,
            cancel_by: resp?.cancel_by
          }
        }
        return resp
      }).then(resp => {
        if (resp?.invoice_status === 'unpaid' || resp?.invoice_status === 'pre-credit-approved' && resp?.payment_method === '2c2p') {
          this.invoice_id = resp?.invoice_id
          this.getCreditPayment2c2pList()
          if (this.account_2c2p_id) {
            this.get2c2pPaymentDetail(resp?.invoice_id, this.account_2c2p_id)
          }
        }
      }).catch((err) => {
        this.is_loading_qrcode = false
      }).finally(() => {
        this.loading = false
      })
    },
    generateQrcode() {
      subscriptionApi.createQrCodePayment(this.subscription_id).then(resp => {
        if (!!resp) {
          this.time = Math.floor(resp?.expire_time / 60);
          this.timer = resp?.expire_time
          this.form = {
            ...this.form,
            reference1: resp?.ref1,
            reference2: resp?.ref2,
            qr_code_img: functionsCommon.convertBase64ToImage(resp?.data?.qrImage),
            transaction_date: resp?.transaction_date
          }
          this.err_qr_code = false
          this.countdownQrcodeTime()
        }
      }).catch(err => {
        this.handleErrorImgQrCode()
      }).finally(() => {
        this.is_loading_qrcode = false
      })
    },
    mapStatus(status) {
      return functionsCommon.colorButtonStatusByText(status);
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    toAllInvoice() {
      this.$router.push('/Invoice').catch(() => {
      })
    },
    changeFormatTotal(total = 0, currency = '฿') {
      return functionsCommon.addCurrencyToPrice(total, currency)
    },
    countdownQrcodeTime() {
      let interval = setInterval(() => {
        if (this.timer === 0) {
          clearInterval(interval)
          this.countdown = '00:00'
        } else {
          const minutes = Math.floor(this.timer / 60);
          const seconds = this.timer % 60;
          this.countdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
          this.is_loading_qrcode = false
          this.timer--
        }
      }, 1000)
    },
    resendQrCode() {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('subscriptions.confirm_resend_qr'),
        html: this.$t('subscriptions.confirm_resend_qr_content'),
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('common.confirm'),
      }).then(result => {
        if (result.isConfirmed) {
          this.timer = 0
          this.is_loading_qrcode = true
          this.generateQrcode()
        }
      })
    },
    checkInQuiry() {
      subscriptionApi.checkInQuiryQrcode(this.subscription_id).then(resp => {
        if (!!resp) {
          this.getSubscriptionByID()
        }
      }).catch(err => {
        alert.fail(this, err?.response?.data?.message)
      })
    },
    cancelSubscription() {
      this.$bvModal.show('show-qrcode-cancel')
    },
    closePopUp() {
      this.$bvModal.hide(`show-qrcode-cancel`)
      this.cancel.cancel_reasons = []
    },
    createCancelSubscription() {
      const map_data = {
        id: this.subscription_id,
        cancel_reasons: this.cancel.cancel_reasons
      }
      this.is_cancel_loading = true
      subscriptionApi.cancelSubscriptionById(map_data).then(resp => {
        if (!!resp) {
          this.$router.push('/Invoice').catch(() => {
          })
          this.closePopUp()
        }
      }).catch(err => {
        if (err?.response?.status !== 401) {
          alert.cancelFailed(this, err?.response?.data?.message)
        }
      }).finally(() => {
        this.is_cancel_loading = false
      })
    },
    handleErrorImgQrCode() {
      const imgElement = document.getElementById('qrcode-payment');
      imgElement.src = require('@/assets/images/icon/no-img.svg');
      this.err_qr_code = true
    },
    changeMapReasonCancel(data) {
      let map_data = data?.split(',').map((item) => {
        let msg = ''
        if (item === '0') {
          msg = this.$t('subscription.checkout.need_to_modify_order')
        } else if (item === '1') {
          msg = this.$t('subscription.checkout.need_to_changed_package')
        } else if (item === '2') {
          msg = this.$t('subscription.checkout.other_or_change_your_mind')
        } else {
          msg = null
        }
        return msg
      })
      return !_.isNil(map_data) ? map_data?.join(' , ') : null
    },
    getCreditProfile() {
      authApi.getCustomerProfile(true).then(resp => {
        return resp
      }).catch(err => {
        throw err
      })
    },
    getCreditPayment2c2pList() {
      settingApi.getPayment2c2pList().then(resp => {
        this.payment_2c2p_list = _.filter(resp?.data, item => {
          return item?.status === 1
        })
      }).catch(err => {
        throw err
      })
    },
    selectBankAccount(data, type, payment_type) {
      const confirm_text = payment_type === '01' ? this.$t('subscription.checkout.credit_card.confirm_credit_card') : payment_type === '02' ? this.$t('subscription.checkout.credit_card.confirm_e_wallet') : this.$t('subscription.checkout.credit_card.confirm_your_credit')
      if (data !== this.account_2c2p_id) {
        this.$swal.fire({
          icon: 'warning',
          text: confirm_text,
          showCancelButton: true,
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.confirm'),
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.account_2c2p_id = data
            this.get2c2pPaymentDetail(this.invoice_id, data)
          }
        })

      }
    },
    get2c2pPaymentDetail(invoice_id, payment_id) {
      const data = {
        invoice_no: invoice_id,
        account_2c2p_id: payment_id
      }
      this.webPaymentUrl = null
      settingApi.getDetail2c2pPayment(data).then(resp => {
        this.webPaymentUrl = resp?.webPaymentUrl
      }).catch(err => {
        throw err
      })
    },
  },
  watch: {
    'time_income'(newTime) {
      if (newTime && this.subscription_id === this.confirm_payment.subscription_id) {
        this.webPaymentUrl = null
        this.getSubscriptionByID()
        this.getCreditProfile()
      }
    },
    'form.invoice_status'(val) {
      if (val === 'paid' || val === 'pre-credit-paid') {
        this.timer = 0
      }
    },
    '$i18n.locale'(val) {
      if (val) {
        this.getSubscriptionByID()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.responsive-invoice-detail-custom {
  grid-template-columns: repeat(1, 1fr) !important;
  width: 100%;

  .grid-item:first-child {
    grid-column: initial !important;
  }
}

.responsive-invoice-detail {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;

  .grid-item:first-child {
    grid-column: span 2;
  }

  .grid-item:last-child {
    grid-column: span 1;
  }

  .custom-bank-number {
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    justify-content: initial;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .grid-item:first-child {
      grid-column: initial;
    }

    .grid-item:last-child {
      grid-column: initial;
    }

    .custom-bank-number {
      font-size: 16px;
    }
  }
}

.responsive-header {
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    .responsive-header--left {
      flex-direction: column;
      align-items: flex-start;
      justify-content: initial;
      width: 100%;
    }

    flex-direction: column;
    align-items: flex-start;
    justify-content: initial;
    gap: 12px;
  }
}

.card-box-secondary {
  border-radius: 1.25rem;
  background: #F0F0F0;
  padding: 1rem;
  justify-content: center;
}

.type-box.personal {
  background: #FFF6E8;
  border: 1px solid #FFC107;
}

.type-box.company {
  background: #FFF6E8;
  border: 1px solid #FFC107;
}

.type-box,
.status-box {
  border-radius: 20px;
  padding: 0 12px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  min-width: 80px;
  height: 22px;
}

.qr-code-box {
  padding: 0 !important;
  //border-radius: 10px !important;
  font-size: 16px !important;
  border: 0;
  //box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);

  .card-header {
    font-size: 18px;
    padding: .5rem;
    background: #113566 !important;
    border-radius: 10px 10px 0 0 !important;
    font-weight: 800 !important;
    margin: 0 !important;
    width: 100% !important;
    border: 0 !important;
  }
}

.type-box.personal {
  background: #C5EDFF;
  border: 1px solid #3CADDE;
  color: #2F2E2E;
  text-align: center;
}

.type-box.company {
  background: #FFE1C5;
  border: 1px solid #F49300;
  color: #2F2E2E;
  text-align: center;
}

.type-box,
.status-box {
  border-radius: 20px;
  padding: 0 12px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  min-width: 80px;
  height: 22px;
}

.transaction-ref {
  margin-right: 25%;
  margin-left: 25%;
}


.text-content-black {
  color: #2F2E2E;
}

.text-content-gray {
  color: #9F9F9F;
}

.disable-qrcode {
  opacity: .2;
}

.button-all-invoice {
  padding-left: 4%;
  padding-right: 4%;
}

.invoice-status {
  background: #e9e9e9;
  color: #000000;
  border-radius: 10px;
  height: 35px;
  vertical-align: center;
  padding: 6px;
  display: block;
}

.invoice-status-test {
  background: #e9e9e9;
  color: #000000;
  border-radius: 10px;
  height: 35px;
  vertical-align: center;
  padding: 6px;
  border-color: #e9e9e9;
  display: block;
}

.reason-box {
  background: #FFE9E9;
  padding: 1rem;
  border-radius: 10px;
  margin: 1rem 0 1rem 0;
  display: flex;
  color: #D30000;
}

.credit-card-img img {
  position: relative;
  width: auto;
  height: 1rem;
  border-radius: 10px;
  margin: .1rem;
  justify-content: center;
}

.bank-card-active {
  border-radius: 10px;
  border: 1px solid var(--primary-color) !important;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0px 1px 20px -12px var(--primary-color) !important;
}

.bank-card {
  background: #FFF;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.10);
  padding: 1rem;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  //min-height: 125px; width: 100%;
}

.qr-code-box-success {
  padding: 1rem !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  border: 1px solid #fbfbfb;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
  margin: 2rem 0 2rem 0 !important;
}
</style>